/*!
 * @author Lucas H <lucas@speak.geek.nz>
 */

import { MobileDeviceWidth } from '@/constants';
import { isMobile } from '@/helpers/isMobile';
import * as React from 'react';
import { useEffect, useState } from 'react';

import styles from '@/styles/hashelp.scss';

type Props = {
  show?: boolean;
  help?: string;
  side?: 'top' | 'right' | 'bottom' | 'left';
  children?: React.ReactNode;
};

function findHelp(props : Props) {
  if (props.help) {
    return props.help;
  }

  const children : React.ReactChild[] = React.Children.toArray(props.children) as React.ReactChild[];

  const childWithHelp = children.find(
    (c) => (
      typeof (c) !== 'string'
      && (c as React.ReactElement).props
      && ((c as React.ReactElement).props.title || (c as React.ReactElement).props.alt)
    ),
  );

  if (childWithHelp) {
    const {
      props: { title, alt, 'data-mobile-title': dataMobileTitle },
    } = childWithHelp as React.ReactElement;
    return props.help || (isMobile(MobileDeviceWidth) ? dataMobileTitle || title : title) || alt;
  }

  return props.help;
}

export function HasHelp(props: Props) {
  let help = findHelp(props);

  const { show = false, side = 'left' } = props;
  const [ timer, setTimerID ] = useState(-1);
  const [ pressed, setPress ] = useState(false);
  const tooltipSide = styles[side];

  useEffect(() => {
    return () => {
      // console.log('clean-up HasHelp timer', timer, pressed);
      if (timer >= 0) {
        clearTimeout(timer);
        setTimerID(-1);
      }
    };
  }, [ timer ]);

  const callbacks = {
    onTouchStart: () => {
      // console.log('touchstart');
      clearTimeout(timer);
      setTimerID(-1);
      setPress(false);

      // console.log('set timer');
      setTimerID(window.setTimeout(() => {
        // console.log('timer fired, pressed => true');
        setPress(true);
      }, 1000));
    },

    onTouchEnd: () => {
      clearTimeout(timer);
      setTimerID(-1);
    },

    onTouchCancel: () => {
      clearTimeout(timer);
      setTimerID(-1);
    },
  };

  const className = `tooltip-help ${styles.hasHelp} ${(show||pressed) ? styles.visible : ''} ${tooltipSide}`;

  return (
    <div data-help={help} {...callbacks} className={className}>
      {props.children}
    </div>
  );
}
