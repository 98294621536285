/*!
 * @author Lucas H <lucas@speak.geek.nz>
 */

import * as React from 'react';
import * as ReactDOM from 'react-dom';

import { ReactInitProps, ReactJigsaw } from '@/components/ReactJigsaw';
import { $ } from '@/util';

import '@/styles/style.scss';
import '../liga.js';

export function render(elt: HTMLElement | null, options?: ReactInitProps) {
  if (!elt) {
    throw new Error(`No element given to react init`);
  }
  ReactDOM.render(
    <ReactJigsaw />,
    elt,
  );
}
import { joinSounds, successSounds } from '@/soundList';

window.addEventListener('load', () => {
  (document as any).fonts.load('1em feather').then(() => {
    console.log('feather loaded? ' + (document as any).fonts.check('1em feather'));  // true
    const root : HTMLElement = $('.container') as HTMLElement;
    render(root);
    // preload
    [...joinSounds, ...successSounds ].forEach(url => fetch(url));
  });
});

