/*!
 * @author Lucas H <lucas@speak.geek.nz>
 */

import * as smartcrop from 'smartcrop';

import { Dim, Rect } from '@/geom';
import { calcCropScale } from '@/helpers/calcCropScale';
import * as logger from '@/logger';
import { canvasToImage, createCanvas } from '@/util';

import type { Immutable } from '@/jtypes';

type HasWidthHeight = {
  readonly width: number;
  readonly height: number;
};

export type ImageSource = {
  url: string;
  crop?: Rect;
};


export class ImageLoader {

  public load(pathOrFile: string|File|ImageSource) : Promise<HTMLImageElement> {
    if (typeof(pathOrFile) === 'string') {
      return this.loadImageFromSrc(pathOrFile);
    }
    const imgOrFile = pathOrFile as object;
    if (imgOrFile instanceof File) {
      return this.loadFile(imgOrFile);
    } else if (imgOrFile instanceof HTMLImageElement) {
      return this.loadImage(imgOrFile);
    } else if ((imgOrFile as ImageSource).url) {
      return this.loadImageFromSrc((imgOrFile as ImageSource).url);
    }

    return Promise.reject(new Error('Unknown pathOrFile type')) as Promise<HTMLImageElement>;
  }

  public loadAndScale(pathOrFile: string|File|ImageSource, targetSize: Immutable<Dim>) : Promise<HTMLImageElement> {
    logger.debug('targetSize', targetSize);
    let crop : Rect|undefined;

    if (typeof (pathOrFile) === 'object' && (pathOrFile as ImageSource).url) {
      ({ crop } = (pathOrFile as ImageSource));
    }

    return this.load(pathOrFile)
    .then (image => {
      const imageDim = new Dim(image);

      if (crop) {
        if (crop.w > 0) { imageDim.w = crop.w; }
        if (crop.h > 0) { imageDim.h = crop.h; }
      }

      const cropRect = this.calcImageScale(imageDim, targetSize);
      let cropDone = Promise.resolve(cropRect);

      if (crop) {
        cropRect.pos.set(crop.pos);
      } else {
        cropDone = smartcrop.crop(image, { width: cropRect.w, height: cropRect.h }).then(result => {
          const { topCrop: r } = result;

          return Rect.fromXYWidthHeight(r.x, r.y, r.width, r.height);
        });
      }

      return cropDone.then(r => this.scaleImage(image, r, targetSize));
    }) as Promise<HTMLImageElement>;
  }

  public loadFile(file: File) : Promise<HTMLImageElement> {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.addEventListener('load', () => {
        this.loadImageFromSrc(reader.result as string)
          .then(resolve)
          .catch(reject);
      });
      reader.readAsDataURL(file);
    });
  }

  public loadImage(image: HTMLImageElement) : Promise<HTMLImageElement> {
    return Promise.resolve(image);
  }

  public loadImageFromSrc(path: string) : Promise<HTMLImageElement> {
    return new Promise((resolve, reject) => {
      const image = new Image();
      image.crossOrigin = 'anonymous';
      image.onload = () => resolve(image);
      image.onerror = (err) => reject(new Error(`Unable to load image from src ${path}: ${err}`));
      image.src = path;
    }) as Promise<HTMLImageElement>;
  }

  private calcImageScale(imageDim: Dim, displayDim: Immutable<Dim>) : Rect {
    return calcCropScale(imageDim, displayDim);
  }

  private scaleImage(image: HTMLImageElement, cropRect: Immutable<Rect>, targetSize: Immutable<Dim>) {
    logger.debug('image native size', new Dim(image));
    logger.debug(`scaling+cropping to : `, targetSize);

    const canvas = createCanvas(targetSize.w, targetSize.h);

    if (!canvas) {
      throw new Error(`Unable to create canvas of desired dimensions: ${targetSize}`);
    }

    const context = canvas.getContext('2d', { alpha: false });

    if (!context) {
      throw new Error(`Unable to get context`);
    }

    context.drawImage(image,
      cropRect.x, cropRect.y, cropRect.w, cropRect.h,
      0, 0, targetSize.w, targetSize.h,
      );

    return canvasToImage(canvas);
  }
}
