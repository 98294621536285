/*!
 * @author Lucas H <lucas@speak.geek.nz>
 */

import { Cacher } from '@/cacher';
import { DefaultJigsawSettings, DefaultNumPieces } from '@/constants';
import { EventEmitter } from '@/eventEmitter';
import { Dim, Point, Rect } from '@/geom';
import { shuffleArrayInPlace } from '@/helpers/shuffleArray';
import * as logger from '@/logger';
import { Piece } from '@/piece';
import { PieceCutter } from '@/pieceCutter';
import { PieceGrouper } from '@/pieceGrouper';
import { CanvasRenderer, SVGRenderer } from '@/renderer';
import { Tongue, TongueType } from '@/tongue';
import { range } from '@/util';

import { JigsawDrawMode } from './jigsawDrawMode';
import { JigsawMode } from './jigsawMode';

import type { DirtyRectangles } from '@/dirty';
import type { Immutable } from '@/jtypes';
import type { Perturber } from '@/perturbers';
import type { AbstractRenderer } from '@/renderer';
import type { TongueParams } from '@/tongue';
import type { FullJigsawOptions, IJigsaw, MovePieceOptions } from './types';

export class Jigsaw extends EventEmitter implements IJigsaw {
  public readonly groups = new PieceGrouper();
  public readonly mode = new JigsawMode();
  public readonly drawMode = new JigsawDrawMode();

  private bounds: Rect = new Rect();
  private _columns = 1;
  private _rows = 1;
  private settings: TongueParams = DefaultJigsawSettings;
  private imageDim = new Dim();

  private pieces: Piece[] = [];
  private piecesInOrder: Piece[] = [];

  private drawOrder: number[] = [];
  private readonly updateRegion: DirtyRectangles;

  private cacher: Cacher;

  constructor(options: FullJigsawOptions) {
    super();

    const {
      columns,
      rows,
      bounds,
      settings,
      updateRegion,
    } = options;

    if (bounds) {
      this.bounds.set(bounds);
    }

    this.updateRegion = updateRegion;
    this.cacher = new Cacher();

    // disable shading on safari
    this.cacher.debug.noShading = !!window.navigator.userAgent.toString().match(/Safari/i);

    this.settings = Object.assign(this.settings, settings);

    this._rows = rows;
    this._columns = columns;
  }

  get columns() {
    return this._columns;
  }

  get rows() {
    return this._rows;
  }

  // @todo jigsaw: move all iteration to traversal classes
  public *[Symbol.iterator]() : Generator<Piece, void, boolean|undefined> {
    for (const piece of this.pieces) {
      const isEarlyExit = yield piece;
      if (isEarlyExit) {
        break;
      }
    }
  }

  public getBounds() : Immutable<Rect> {
    return this.bounds;
  }

  public init(image: HTMLImageElement) {
    this.emit('reset', image);
    this.imageDim.set(image.width, image.height);

    const { rows, columns } = this;
    this.setSize(columns, rows, { reset: false });
    this.setImage(image, { reset: false });
    this.resetPieces();
    this.emit('jigsaw-ready', this);
  }

  public resetPieces() {
    this.emit('pre-reset-pieces');

    const { columns, rows, imageDim } = this;

    const bounds = this.getBounds();
    const jigsawDim = bounds.dimensions;
    const screenOffset = jigsawDim.clone();

    screenOffset.w /= columns;
    screenOffset.h /= rows;
    screenOffset.scale(0.5).add(bounds.topLeft);

    this.cutPieces();

    this.cacher.init({ rows, columns, minPieceDim: this.findLargestPieceDim() });

    this.pieces.forEach(piece => {
      this.cacher.put(piece);
    });

    this.initDrawOrder();
    this.emit('post-reset-pieces');
  }

  public setSize(columns: number, rows: number, { reset } = { reset: true }) {
    this._rows = rows;
    this._columns = columns;
    if (reset) {
      this.resetPieces();
    }
  }

  public setImage(image: HTMLImageElement, { reset } = { reset: true }) {
    this.imageDim.set(image.width, image.height);
    this.cacher.setImage(image);
    if (reset) {
      this.resetPieces();
    }
  }

  public perturb(p: Perturber) {
    p.run(this.pieces, this);
    this.markAsDirty();
  }

  public markAsDirty() {
    this.updateRegion.invalidate();
  }

  public markPieceDirty(piece: Piece) {
    this.updateRegion.markPieceDirty(piece);
  }

  public isDirty() {
    return this.updateRegion.isDirty();
  }

  public getSettings() : Readonly<TongueParams> {
    return this.settings;
  }

  public joinPieces(pieceA: Piece, pieceB: Piece) {
    if (this.groups.mergePieceGroups(pieceA, pieceB)) {
      this.lowerPieceToBottom(pieceA);
      this.emit('pieces-joined', this.groups.getGroupFor(pieceA));
    }

    const newGroup = this.groups.getGroupFor(pieceA);
    if (newGroup && newGroup.length === this.pieces.length) {
      this.emit('completed');
    }
  }

  public lowerPieceToBottom(piece: Piece,
    { dirty = true, groups = true }: MovePieceOptions = {},
  ) {
    if (!piece) {
      throw new Error(`lowerPieceToBottom not given a piece`);
    }
    // move all items in group to top
    const members = (groups && this.groups.getOtherGroupMembers(piece)) || [];
    for (const p of members) {
      if (p !== piece) {
        if (dirty) {
          this.markPieceDirty(p);
        }
        this.moveToBottomOfDrawOrder(p);
      }
    }
    this.moveToBottomOfDrawOrder(piece);
    this.markPieceDirty(piece);
  }

  public raisePieceToTop(piece: Piece,
    { dirty = true, groups = true }: MovePieceOptions = {},
  ) {
    if (!piece) {
      throw new Error(`raisePieceToTop not given a piece`);
    }
    const members = (groups && this.groups.getOtherGroupMembers(piece)) || [];
    for (const p of members) {
      if (p !== piece) {
        if (dirty) {
          this.markPieceDirty(p);
        }
        this.moveToTopOfDrawOrder(p);
      }
    }
    this.moveToTopOfDrawOrder(piece);
    this.markPieceDirty(piece);
  }

  public movePieceBy(piece: Piece, dx: number, dy: number,
    { dirty = true, groups = true }: MovePieceOptions = {},
  ) {
    const group = (groups && this.groups.getGroupFor(piece)) || [ piece ];

    for (const p of group) {
      if (dirty) {
        this.markPieceDirty(p);
      }
      p.setPos(p.getPos().plus(dx, dy));
      if (dirty) {
        this.markPieceDirty(p);
      }
    }
  }

  public movePieceTo(piece: Piece, x: number, y: number,
    { dirty = true, groups = true }: MovePieceOptions = {},
  ) {
    const delta = new Point(x, y).sub(piece.getPos());

    if (dirty) {
      this.markPieceDirty(piece);
    }
    piece.setPos(new Point(x, y));
    if (dirty) {
      this.markPieceDirty(piece);
    }

    if (groups) {
      for (const p of this.groups.getOtherGroupMembers(piece)) {
        this.movePieceBy(p, delta.x, delta.y, { dirty, groups: false });
      }
    }
  }

  public getNeighbours(ofPiece: Piece,
    { includeGroup = false }: { includeGroup?: boolean} = {},
  ) : Piece[] {

    const neighbours : Piece[] = [];

    if (ofPiece.row > 0) {
      neighbours.push(this.getPiece(ofPiece.column, ofPiece.row - 1));
    }

    if (ofPiece.row < this.rows - 1) {
      neighbours.push(this.getPiece(ofPiece.column, ofPiece.row + 1));
    }

    if (ofPiece.column > 0) {
      neighbours.push(this.getPiece(ofPiece.column - 1, ofPiece.row));
    }

    if (ofPiece.column < this.columns - 1) {
      neighbours.push(this.getPiece(ofPiece.column + 1, ofPiece.row));
    }

    return neighbours;
  }

  public snapPieces(pieceToMove: Piece, includeGroup = false) : boolean {
    if (!pieceToMove) {
      throw new Error(`snapPieces given non-piece`);
    }

    let hasSnapped = false;
    const newPos = new Point();
    const piecesToJoin : Piece[] = [];

    const neighbours = this.getNeighbours(pieceToMove, { includeGroup: false });
    // logger.debug('snapPieces, neighbours for ', pieceToMove.id, 'are', neighbours.map(p => p.id));

    for (const pieceToSnapTo of neighbours) {
      // logger.debug('snap check to ', pieceToSnapTo.id);

      if (this.mode.onlyDrawEdgePieces && !pieceToSnapTo.isEdge) {
        // logger.debug('not snapping to non-edge piece', pieceToSnapTo.id);
        continue;
      }
      if (this.groups.areInSameGroup(pieceToMove, pieceToSnapTo)) {
        // logger.debug('ignoring piece in same group', pieceToSnapTo.id);
        continue;
      }

      if (pieceToMove.canFitInto(pieceToSnapTo)) {
        // logger.debug('piece', pieceToMove.id, 'CAN fit into', pieceToSnapTo.id);
        if (pieceToMove.calcSnapTo(pieceToSnapTo, newPos)) {
          // logger.debug('snap ', pieceToMove.id, 'to', pieceToSnapTo.id);
          this.movePieceTo(pieceToMove, newPos.x, newPos.y);
          this.joinPieces(pieceToMove, pieceToSnapTo);
          piecesToJoin.push(pieceToSnapTo);
          hasSnapped = true;
        } else {
          // logger.debug('calcSnapTo returned false');
        }
      } else {
        // logger.debug('piece', pieceToMove.id, 'cannot fit into', pieceToSnapTo.id);
      }
    }

    if (includeGroup) {
      const members = this.groups.getGroupFor(pieceToMove);
      if (members) {
        for (const groupPiece of members) {
          if (groupPiece === pieceToMove) {
            continue;
          }
          // logger.debug('recursing for group member', groupPiece.id);
          if (this.snapPieces(groupPiece, false)) {
            hasSnapped = true;
          }
        }
      }
    }

    // piecesToJoin.forEach(p => this.joinPieces(pieceToMove, p));

    return hasSnapped;
  }

  public findPieceByPoint(point: Readonly<Point>, { reverse = false, onlyEdges = false } = {}) : Piece|false {
    let list = this.piecesInOrder;

    if (!reverse) {
      list = list.slice().reverse();
    }

    for (const piece of list) {
      if (onlyEdges && !piece.isEdge) {
        continue;
      }

      if (piece.getBoundingRect().isPointInside(point)) {
        logger.debug('found piece', piece.getInnerRect());

        return piece;
      }
    }

    return false;
  }

  public findPiecesByRect(rect: Readonly<Rect>, { reverse = false, onlyEdges = false } = {}) {
    const found : Piece[] = [];
    let list = this.pieces;

    if (!reverse) {
      list = this.pieces.slice().reverse();
    }

    for (const piece of list) {
      if (onlyEdges && !piece.isEdge) {
        continue;
      }

      if (rect.hasIntersect(piece.getBoundingRect())) {
        found.push(piece);
      }
    }

    return found;
  }

  public drawPiece(renderer: AbstractRenderer, piece: Piece) {
    const { drawMode } = this;

    if (!drawMode.onlyDrawPath && renderer instanceof CanvasRenderer && this.cacher.has(piece)) {
      // throw new Error(`Piece ${piece.id} has no cached image`);
      const { image, rect } = this.cacher.get(piece);
      const bounds = piece.getBoundingRect();

      renderer.save();

      if (piece.angle !== 0 && renderer instanceof CanvasRenderer) {
        renderer.getContext().translate(piece.getPos().x, piece.getPos().y);
        renderer.getContext().rotate(piece.angle);
        renderer.getContext().translate(- piece.getPos().x, - piece.getPos().y);
      }

      renderer.getContext().drawImage(image, rect.topLeft.x, rect.topLeft.y,
        rect.w, rect.h,
        bounds.topLeft.x, bounds.topLeft.y,
        rect.w, rect.h,
      );
      renderer.restore();
      // renderer.getContext().filter = 'none';
      // this.#outlinePiecePath(renderer, piece);
    }
    if (drawMode.showPath || drawMode.showBounds) {
      renderer.save();
    }
    if (drawMode.showPath || drawMode.onlyDrawPath) {
      const path = piece.toPath({ offset: piece.getPos() });

      renderer.strokeStyle = 'black';
      renderer.lineWidth = 1;
      renderer.stroke(path);
    }
    if (drawMode.showBounds) {
      let b = piece.getBoundingRect();
      renderer.strokeStyle = '#f0f';
      renderer.strokeRect(b.x, b.y, b.w, b.h);

      b = piece.getInnerRect();
      renderer.strokeStyle = '#0FF';
      renderer.strokeRect(b.x, b.y, b.w, b.h);

      renderer.beginPath();
      renderer.fillStyle = '#F80';
      renderer.ellipse(piece.getPos().x, piece.getPos().y, 10, 10, 0, 0, Math.PI);
      renderer.fill();

      // renderer.strokeStyle = 'rebeccapurple';
      // b = piece.sourceRect;
      // renderer.strokeRect(b.x, b.y, b.w, b.h);
    }
    if (drawMode.showConstruction) {
      piece.drawConstruction(renderer);
    }
    if (drawMode.showIDs) {
      renderer.fillStyle = '#f9f';
      renderer.textAlign = 'left';
      renderer.font = 'bold 15px monospace';
      renderer.fillText(piece.id, piece.getPos().x, piece.getPos().y+15);
    }
    if (drawMode.showPath || drawMode.showBounds) {
      renderer.restore();
    }
    this.emit('draw piece', piece.id);
  }

  public toSVG() : string {
    const renderer = new SVGRenderer(this.bounds);
    // updateArea.filter(
    this.pieces.forEach((piece: Piece) => {
      this.drawPiece(renderer, piece);
    });

    return renderer.toString();
  }

  public draw(renderer: AbstractRenderer) {
    if (!this.isDirty()) {
      return;
    }

    this.updateDrawingOrder();

    const drawList = this.updateRegion.filter(this.piecesInOrder);
    // logger.debug('drawList', this.piecesInOrder);
    // logger.debug('filtered to region', drawList);

    for (const piece of drawList) {
      if (!this.mode.onlyDrawEdgePieces || piece.isEdge) {
        // logger.debug('drawing piece', piece.id);
        this.drawPiece(renderer, piece);
      }
    }

    this.mode.unflag();
    this.drawMode.unflag();
  }

  public getCacherImage() {
    return this.cacher?.getCanvasImage();
  }

  public highlightPiece(renderer: AbstractRenderer, piece: Piece) {
    const path = piece.toPath({ offset: piece.getPos() });
    renderer.save();
    if (renderer instanceof CanvasRenderer) {
      renderer.getContext().filter = 'blur(5px)';
    }
    renderer.strokeStyle = 'rgba(255, 0, 255, 1)';
    renderer.lineWidth = 5;
    renderer.stroke(path);
    renderer.restore();
  }

  public getHint() : [Piece, Piece] {
    const indices = [...range(this.pieces.length)];
    shuffleArrayInPlace(indices);

    for (let i = 0; i < indices.length; i++) {
      const piece = this.pieces[indices[i]];

      if (this.groups.isPieceInAnyGroup(piece)) {
        continue;
      }
      if (this.mode.onlyDrawEdgePieces && !piece.isEdge) {
        continue;
      }
      const neighbours = this.getNeighbours(piece).filter(p => {
        if (this.mode.onlyDrawEdgePieces && !p.isEdge) {
          return false;
        }

        return true;
      });
      if (neighbours.length) {
        return [ piece, neighbours[Math.floor(Math.random() * neighbours.length)] ];
      }
    }

    throw new Error(`No hint possible, jigsaw finished?`);
  }

  public getPiece(column: number, row: number) {
    const index = this.getPieceIndex(column, row);

    if (index < 0 || index >= this.pieces.length) {
      throw new Error(`Piece <${column}, ${row}> out of bounds (idx ${index})`);
    }

    return this.pieces[ index ];
  }

  public debugCacher() {
    window.open(this.cacher.getCanvasImage());

    return;
/*
    return import('@/util').then(({ createCanvas }) => {
      const { image } = this.cacher;
      const canvas = createCanvas(image.width, image.height);
      if (!canvas) {
        throw new Error(`No canvas`);
      }
      const context = canvas.getContext('2d');
      if (!context) {
        throw new Error(`No context`);
      }
      context.drawImage(image, 0, 0);
      for (const p of this) {
        const path : Path2D = p.toPath({ offset: p.points[0] }) as Path2D;
        const blitRect = this.cacher.getPieceBlitRect(p);
        const gridRect = this.cacher.getPieceGridRect(p);

        context.save();
        // context.clip(path);
        // context.drawImage(image, 0 - p.sourceRect.topLeft.x, 0 - p.sourceRect.topLeft.y);
        context.strokeStyle = '#FF00FF';
        context.strokeRect(
          p.sourceRect.x,
          p.sourceRect.y,
          p.sourceRect.w,
          p.sourceRect.h,
        );
        context.strokeStyle = '#FFFF00';
        context.stroke(path);

        context.fillStyle = '#00FFFF';
        context.ellipse(p.getPos().x, p.getPos().y, 10, 10, 0, 0, Math.PI * 2);
        context.fill();
        context.fillText(p.id, p.getPos().x+10, p.getPos().y+20);
        context.restore();

        // context.clip(path);
        // context.drawImage(this.image, 0 - p.sourceRect.topLeft.x, 0 - p.sourceRect.topLeft.y);
      }
      window.open(canvas.toDataURL());
    })
    .catch((e: Error) => {
      logger.error('debugCacher', e);
    }); */
  }

  private cutPieces() {
    const { columns, rows, imageDim } = this;

    const cutter = new PieceCutter(columns, rows);
    const pieceDefs = cutter.cut(imageDim, this.getBounds().dimensions);

    this.pieces.length = 0;

    for (const { screenRect, tongues, ...initOptions} of pieceDefs) {
      // const { screenRect, tongues, ...initOptions } = def;
      const { extrusion, ...settings } = this.getSettings();

      // create tongues from enum
      const sides = tongues.map(type => (
        type === TongueType.NONE
          ? false
          : new Tongue({
            ...settings,
            extrusion:  type === TongueType.OUT ? extrusion : (0 - extrusion),
          },
        )
      ));

      const piece = new Piece({
        ...initOptions,
        w: screenRect.w,
        h: screenRect.h,
        pos: screenRect.pos.plus(this.bounds.topLeft),
        sides,
      });

      // logger.debug('cut piece', 'scr', screenRect, 'src', def.sourceRect, 'bounds', piece.getBoundingRect(), 'offset', piece.offset);
      piece.updateDimensions();
      // logger.debug('cut piece updated dimensions', 'bounds', piece.getBoundingRect(), 'offset', piece.offset);
      this.pieces.push(piece);
    }
    this.emit('cut-pieces');
  }

  private initDrawOrder() {
    // initialize to array in pieces order
    this.piecesInOrder.length = this.pieces.length;
    this.drawOrder = this.pieces.map((p,i) => i);
  }

  private moveToTopOfDrawOrder(p: Piece) {
    const pieceIndex = this.getPieceIndex(p);
    const { drawOrder } = this;
    const drawIndex = drawOrder.indexOf(pieceIndex);

    drawOrder.splice(drawIndex, 1);
    drawOrder.push(pieceIndex);
  }

  private moveToBottomOfDrawOrder(p: Piece) {
    const pieceIndex = this.getPieceIndex(p);
    const { drawOrder } = this;
    const drawIndex = drawOrder.indexOf(pieceIndex);

    drawOrder.splice(drawIndex, 1);
    drawOrder.unshift(pieceIndex);
  }

  private getPieceIndex(pOrCol: Piece|number, row: number = 0) : number {
    if (pOrCol instanceof Piece) {
      return pOrCol.row * this.columns + pOrCol.column;
    }

    return row * this.columns + (pOrCol as number);
  }

  private updateDrawingOrder() {    // setup array in drawing order
    if (this.drawOrder.length !== this.pieces.length) {
      throw new Error(`Draw order is out of sync`);
    }
    for (let i = 0; i < this.drawOrder.length; i++) {
      this.piecesInOrder[i] = this.pieces[this.drawOrder[i]];
    }
  }

  private findLargestPieceDim() {
    // find largest piece
    const largestDim = new Dim(0,0);

    for (const piece of this) {
      const bounds = piece.getBoundingRect();
      largestDim.w = Math.ceil(Math.max(bounds.w, largestDim.w));
      largestDim.h = Math.ceil(Math.max(bounds.h, largestDim.h));
    }

    return largestDim;
  }

}

